<template>
    <div class="system-messages-component">
        <div class="modal fade" ref="systemMessageModal" id="systemMessageModal" tabindex="-1" aria-labelledby="systemMessageModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="systemMessageModalLabel">Системне повідомлення</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <ul v-if="messagesCount">
                            <li v-for="message in messages">
                                {{message.msg}}
                                <span class="d-none">{{message.exp}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <button
                            v-for="button in successButtons" :key="button.alias"
                            type="button"
                            class="btn"
                            :class="button.colorClass || 'btn-primary'"
                            data-bs-dismiss="modal"
                            @click="executeSuccessCallback(button.alias)"
                        >
                            {{button.name}}
                        </button>

                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрити</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

/**
 * Отображает попап с системными уведомлениями
 *
 * Если увдеоление имеет возможность дополнительной success кнопки, то такие кнопки будут отображаться около кнопки Close
 *
 * Если вместе с уведомлениями приходят колбеки на нажатиме Close и Success кнопок, то они также отработают при соответсвующих событиях
 *
 * Данные в initData могут поступать в двох видах: с колбеками и без
 *
 * С колбеками:
 * {
list: {id1: {id: id1, msg: '', exp: '', buttons: [ {alias: '', name: '', colorClass: ''}] }, id2: {}, ...},
successCallback: buttonAlias => {}, // в этом колбеке можна выполнить алгоритм в зависимости от того на какую кнопку нажали
closeCallback: () => {},
}
 *
 * Без колбеков (deprecated)
 * {id1: {}, id2: {}, ...}
 */

export default {
    name: 'SystemMessages',
    components: {

    },
    props: {
        initData: {
            type: Object,
            required: true
        }
    },
    emits: [ 'closed' ],
    data () {
        return {
            isSuccessCallbackExecuted: false,
        }
    },
    computed: {
        // Даные об уведомлениях могут прийти в двух форматах:
        // когад есть колбеки и когда их нет.
        // В первом случає уведомления будут в this.initData.data
        // Во втором случає уведомления будут в this.initData
        messages () {
            let messages = this.initData;
            if (typeof messages == 'object') {
                if (messages.hasOwnProperty('list')) {
                    messages = messages.list;
                }

                return Object.values(messages).filter(item => {
                    return typeof item == 'object' &&
                        item.hasOwnProperty('id') &&
                        item.hasOwnProperty('msg') &&
                        item.hasOwnProperty('exp') &&
                        item.hasOwnProperty('buttons');
                });
            }
            return [];
        },
        messagesCount () {
            return this.messages.length;
        },
        successButtons () {
            let buttons = {};
            if (this.messagesCount) {
                for (let msg of this.messages) {
                    if (msg.hasOwnProperty('buttons') && Array.isArray(msg.buttons)) {
                        for (let btn of msg.buttons) {
                            if (btn.hasOwnProperty('alias') && btn.alias) {
                                buttons[btn.alias] = {...btn};
                            }
                        }
                    }
                }
            }
            return Object.values(buttons);
        },
        successCallback () {
            if (
                typeof this.initData == 'object' &&
                this.initData.hasOwnProperty('successCallback') &&
                typeof this.initData.successCallback === 'function'
            )
            {
                return this.initData.successCallback;
            }
            return buttonAlias => {};
        },
        closeCallback () {
            if (
                typeof this.initData == 'object' &&
                this.initData.hasOwnProperty('closeCallback') &&
                typeof this.initData.closeCallback === 'function'
            )
            {
                return this.initData.closeCallback;
            }
            return () => {};
        },
    },
    methods: {
        executeSuccessCallback (buttonAlias) {
            this.isSuccessCallbackExecuted = true;
            this.successCallback(buttonAlias);
        }
    },
    mounted () {
        const modal = new Modal(this.$refs.systemMessageModal, {});
        this.$refs.systemMessageModal.addEventListener('hidden.bs.modal', () => {
            // Этот функционал выполняется всегда когда прячется попап
            // Success кнопки также прячут попап, поэтому, чтобы close колбек и события не срабатывали
            // делаем проверку на выполнение success колбека
            if (!this.isSuccessCallbackExecuted) {
                this.closeCallback();
            }
            this.$emit('closed', this.initData);
        });
        if (this.messagesCount) {
            this.isSuccessCallbackExecuted = false;
            modal.show();
        }
    },
    updated () {
        const modal = new Modal(this.$refs.systemMessageModal, {});
        console.log(modal);
        console.log(this.messagesCount);
        if (this.messagesCount) {
            this.isSuccessCallbackExecuted = false;
            modal.show();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
