<template>
  <div class="users-component">
      <header>
          <h1>Учасники</h1>
      </header>
      <Users
          :init-users="users"
          @submit="update"
      />
  </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Users from '@/components/blocks/users/Users.vue'

    export default {
        name: 'UsersView',
        components: {
            Users
        },
        computed: {
            ...mapGetters([
                'users'
            ]),
        },
        methods: {
            async update (users) {
                this.$store.commit(`updateUsers`, users);
                await this.$store.dispatch(`updateStateToDb`);
            }
        }
    }
</script>
