import {createRouter, createWebHistory} from 'vue-router'
import UsersView from '../views/UsersView.vue'

const routes = [
    {
        path: '/',
        name: 'users',
        component: UsersView,
        meta: { title: 'Учасники' }
    },
    {
        path: '/user/:id/:section(.*)',
        name: 'user',
        component: () => import('../views/UserView.vue'),
        meta: { title: 'Учасник' }
    },
    {
        path: '/articles/:parentId*',
        name: 'articles',
        component: () => import('../views/ArticlesView.vue'),
        meta: { title: 'Бібліотека' }
    },
    {
        path: '/ranks_categorys',
        name: 'ranks_categorys',
        component: () => import('../views/RanksCategorysView.vue'),
        meta: { title: 'Особистий склад учасників' }
    },
    {
        path: '/ranks',
        name: 'ranks',
        component: () => import('../views/RanksView.vue'),
        meta: { title: 'Звання учасників' }
    },
    {
        path: '/skills',
        name: 'skills',
        component: () => import('../views/SkillsView.vue'),
        meta: { title: 'Навички' }
    },
    {
        path: '/rewards',
        name: 'rewards',
        component: () => import('../views/RewardsView.vue'),
        meta: { title: 'Відзнаки' }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = 'WIWI Cubs - ' + to.meta.title;
    next();
})

export default router
