<template>
    <div class="system-toast-component">
        <div class="toast-container position-fixed top-0 p-3 start-50 translate-middle-x">
            <div ref="toast" class="toast align-items-center text-white border-0" :class="statusClass" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                        {{message}}
                    </div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from "bootstrap";

export default {
    name: 'SystemToast',
    components: {

    },
    props: {
        initData: {
            type: Object,
            required: true
        }
    },
    emits: [ 'closed' ],
    data () {
        return {

        }
    },
    computed: {
        message () {
            return this.initData.message;
        },
        statusClass () {
            return this.initData.status === 'success' ? 'bg-success' : 'bg-primary';
        }
    },
    methods: {

    },
    mounted () {
        let toast = new Toast(this.$refs.toast, {delay: 3000});
        this.$refs.toast.addEventListener('hidden.bs.toast', () => {
            this.$emit('closed', this.initData);
        });
        if (this.message) {
            toast.show();
        }
    },
    updated () {
        let toast = new Toast(this.$refs.toast, {delay: 3000});
        if (this.message) {
            toast.show();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
