<template>
    <div class="user-rating-component btn-group">
        <button
            type="button"
            class="btn"
            :class="buttonColor"
            @click="setPresent()"
        >
            {{ rating }}%
        </button>
        <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown" aria-expanded="false">
            <span class="visually-hidden">Toggle Dropdown</span>
        </button>
        <ul class="dropdown-menu">
            <li>
                <a
                    class="dropdown-item text-info"
                    href="#"
                    @click="setPresent()"
                >
                    <i class="fa-regular fa-face-rolling-eyes"></i>
                    Присутній
                </a>
            </li>
            <li>
                <a
                    class="dropdown-item text-success"
                    href="#"
                    @click="setStrive()"
                >
                    <i class="fa-regular fa-face-grin-wide"></i>
                    Старався
                </a>
            </li>
            <li>
                <a
                    class="dropdown-item text-danger"
                    href="#"
                    @click="setViolate()"
                >
                    <i class="fa-regular fa-face-tired"></i>
                    Порушував
                </a>
            </li>
            <li>
                <a
                    class="dropdown-item"
                    href="#"
                    @click="setAbsent()"
                >
                    <i class="fa-regular fa-face-meh-blank"></i>
                    Відсутній
                </a>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'UserRating',
    components: {

    },
    props: {
        initUsers: {
            type: Array,
            required: true
        },
        initCurrentUserId: {
            type: Number,
            required: true
        },
    },
    emits: [ 'submit' ],
    data () {
        return {

        }
    },
    computed: {
        todayData () {
            return new Date().toISOString().split('T')[0];
        },
        // Повертає супер користувача на основі вхідних даних
        superUser () {
            let superUser = this.initUsers.reduce((acc, user) => user.id === 1 ? user : acc, undefined);
            if (!superUser) throw new Error("Супер користувач відсутній");
            superUser = this.updateSuperUserRating(superUser, this.initUsers);
            return superUser;
        },
        currentUser () {
            const currentUser = this.initUsers.reduce((acc, user) => user.id === this.initCurrentUserId ? user : acc, undefined);
            if (!currentUser) throw new Error(`Користувач з ID ${this.initCurrentUserId} відсутній`);
            // Якщо сьогодні в користувача не було оцінки, то обнулимо його ratingToday, підготувавши для подальших обчислень
            if (currentUser.ratingToday.date !== this.todayData) {
                currentUser.ratingToday = {
                    date: '',
                    value: 0,
                }
            }
            return currentUser;
        },
        // Повертає колір, який відповідає вибраній сьогодні оцінці
        buttonColor () {
            const value = this.currentUser?.ratingToday?.value;

            if (this.currentUser?.ratingToday?.date === this.todayData) {
                switch (value) {
                    case 1:
                        return 'btn-info'; // користувач просто був присутній і нічим особливим не відзначився
                    case 2:
                        return 'btn-success'; // користувач старався
                    case -1:
                        return 'btn-danger'; // користувач чудив
                }
            }

            return 'btn-light'; // користувач не був на події
        },
        // Рейтинг користувача вираховуюється відносто рейтингу супер пористувача
        // Супер користувач завжди має найвищий рейтинг
        rating () {
            const superUserRating = this.superUser?.rating || 1;
            const userRating = this.currentUser?.rating;
            return parseFloat(userRating / superUserRating * 100).toFixed(0);
        }
    },
    methods: {
        // Якщо сьогодні жоден користувач немав оцінки (навіть "присутній"), то події не було
        // Всі рейтинги рахуються відносно рейтингку супер користувача тому, якщо подія була, то в нього має бути
        // навища оцінка за сьогодні і відповідний рейтин
        // Якщо ж події не було, то в супер має не бути оцінки і рейтинг має залишитись незмінним
        updateSuperUserRating (superUser, users) {
            // Якщо серед користувачів, крім супер, немає сьогоднішнього rating, то подія не відбулась, тому і рейтенги не мінятимуться
            const isEvent = users.some(user => user.id !== 1 && user.ratingToday.date === this.todayData);

            // Якщо є подія
            if (isEvent) {
                // і це ще не відображено в супер користувача, то відобразимо
                if (superUser.ratingToday.date !== this.todayData) {
                    superUser.rating += 2;
                    superUser.ratingToday = {
                        date: this.todayData,
                        value: 2,
                    };
                }
            }
            // якщо немає події
            else {
                // а в супер користувача вказано, що вона є, то відмінема
                if (superUser.ratingToday.date === this.todayData) {
                    superUser.rating -= 2;
                    superUser.ratingToday = {
                        date: '',
                        value: 0,
                    };
                }
            }
            return superUser;
        },
        // В списку всіх користувачів обновлює інформацію про рейтинг супер користувача,
        // залежно від того чи є сьогодні оцінка хоча б в когось
        updateSuperUserIn (users) {
            for (let user of users) {
                if (user.id === this.superUser.id) {
                    user = {...this.updateSuperUserRating(user, users)};
                }
            }
            return users;
        },
        updateCurrentUserRating (value) {
            // Від старого рейтенгу віднімаємо сьогоднішню оцінку, якщо така є (див. currentUser) і додаємо дану оцінку
            return this.currentUser.rating - (this.currentUser.ratingToday?.value || 0) + value;
        },
        setPresent () {
            const value = 1;
            let users = this.initUsers;
            for (let user of users) {
                if (user.id === this.currentUser.id) {
                    user.rating = this.updateCurrentUserRating(value);
                    user.ratingToday = {date: this.todayData, value: value };
                }
            }
            this.$emit('submit', this.updateSuperUserIn(users));
        },
        setStrive () {
            const value = 2;
            let users = this.initUsers;
            for (let user of users) {
                if (user.id === this.currentUser.id) {
                    user.rating = this.updateCurrentUserRating(value);
                    user.ratingToday = {date: this.todayData, value: value};
                }
            }
            this.$emit('submit', this.updateSuperUserIn(users));
        },
        setViolate () {
            const value = -1;
            let users = this.initUsers;
            for (let user of users) {
                if (user.id === this.currentUser.id) {
                    user.rating = this.updateCurrentUserRating(value);
                    user.ratingToday = {date: this.todayData, value: value};
                }
            }
            this.$emit('submit', this.updateSuperUserIn(users));
        },
        setAbsent () {
            const value = 0;
            let users = this.initUsers;
            for (let user of users) {
                if (user.id === this.currentUser.id) {
                    user.rating = this.updateCurrentUserRating(value);
                    user.ratingToday = {date: '', value: value};
                }
            }
            this.$emit('submit', this.updateSuperUserIn(users));
        }
    },
    mounted () {

    },
    updated () {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
