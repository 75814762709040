<template>

    <SystemMessages
        :init-data="messages"
        @closed="resetMessages"
    />

    <SystemToast
        :init-data="toast"
        @closed="resetToast"
    />

    <nav class="navbar bg-light fixed-top">
        <div class="container-fluid">
            <div v-if="scrolledOutHeader" class="scrolled-out-header">
                <a href="#">WIWI Cubs</a>
                <small>{{ scrolledOutHeader }}</small>
            </div>
            <a v-else class="navbar-brand" href="#">WIWI Cubs</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div ref="offCanvasNavBar" class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="true"
                 tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasNavbarLabel">WIWI Cubs</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                        <li class="nav-item">
                            <router-link to="/" @click="hideOffcanvas">
                                <i class="fa-solid fa-user-group"></i>
                                Учасники
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/articles" @click="hideOffcanvas">
                                <i class="fa-solid fa-book"></i>
                                Бібліотека
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <hr/>
                        </li>
                        <li class="nav-item">
                            <router-link to="/ranks_categorys" @click="hideOffcanvas">
                                <i class="fa-solid fa-ranking-star"></i>
                                Склад
                            </router-link>
                            <small>Категорії звань</small>
                        </li>
                        <li class="nav-item">
                            <router-link to="/ranks" @click="hideOffcanvas">
                                <i class="fa-brands fa-shirtsinbulk"></i>
                                Звання
                            </router-link>
                            <small>Учасники отримують за здобуття певних вмінь</small>
                        </li>
                        <li class="nav-item">
                            <router-link to="/skills" @click="hideOffcanvas">
                                <i class="fa-solid fa-icons"></i>
                                Вміння
                            </router-link>
                            <small>Вміння учасників і нормативи, які їх підтверджують</small>
                        </li>
                        <li class="nav-item">
                            <hr/>
                        </li>
                        <li class="nav-item">
                            <router-link to="/rewards" @click="hideOffcanvas">
                                <i class="fa-solid fa-trophy"></i>
                                Відзнаки
                            </router-link>
                            <small>Різні відзнаки, якими заохочуються учасники</small>
                        </li>
                        <li class="nav-item">
                            <hr/>
                        </li>
                        <li class="nav-item">
                            <div>
                                <div v-if="!isSyncProgress">
                                    <a
                                        href="#"
                                        @click.prevent="syncState"
                                    >
                                        <i class="fa-solid fa-retweet"></i>
                                        Синхронізувати
                                    </a>
                                    <small>Синхронізувати дані з сервером</small>
                                </div>
                                <div v-if="isSyncProgress">
                                    <div style="display: flex; align-items: center; justify-content: space-between;">
                                        <span class="in-progress">
                                            <i class="fa-solid fa-retweet"></i>
                                            Синхронізувати
                                        </span>
                                        <div class="progress blob" style="position: relative; height: 20px; z-index: 11051;">
                                            <div
                                                class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                role="progressbar"
                                                style="width: 100%; padding: 0px 10px 0px 10px;"
                                            >Синхронізовую...</div>
                                        </div>
                                    </div>
                                    <small  style="position: relative; color: black; z-index: 11051;">
                                        <span v-html="syncProgressInfo"></span>
                                        <button v-if="displaySyncCancelBtn" class="btn btn-danger" style="width: 100%;" @click.stop.prevent="cancelStateSync">Відмінити синхронізацію</button>
                                        <span v-if="displaySyncCancelBtn">Завантаження почнеться через кілька секунд...</span>
                                    </small>
                                    <div style="position: fixed; background-color: Black; opacity: 0.3; top: 0px; left:0px; height: 100vh; width: 100vw;"></div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <div v-if="!isDefaultDataProgress">
                                <a href="#" @click.prevent="resetState">
                                    <i class="fa-solid fa-rotate-right"></i>
                                    Обнулити
                                </a>
                            </div>
                            <div v-if="isDefaultDataProgress">
                                <div style="display: flex; align-items: center; justify-content: space-between;">
                                        <span class="in-progress">
                                            <i class="fa-solid fa-retweet"></i>
                                            Обнулити
                                        </span>
                                    <div class="progress blob" style="position: relative; height: 20px; z-index: 11051;">
                                        <div
                                            class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                                            role="progressbar"
                                            style="width: 100%; padding: 0px 10px 0px 10px;"
                                        >Обнуляю...</div>
                                    </div>
                                </div>
                                <small style="position: relative; color: black; z-index: 11051;">Завантажую дані по-замовчуванню...</small>
                                <div style="position: fixed; background-color: Black; opacity: 0.3; top: 0px; left:0px; height: 100vh; width: 100vw; "></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>

    <router-view
        @scrolledOutHeader="scrolledOutHeader = $event"
    />

</template>

<script>
import { Offcanvas } from 'bootstrap'
import SystemMessages from "@/components/atoms/SystemMessages";
import SystemToast from "@/components/atoms/SystemToast";
import { mapState } from 'vuex'

export default {
    name: 'App',
    components: {
        SystemMessages,
        SystemToast
    },
    props: {},
    data() {
        return {
            // Мінімізований в nav header до якого доскролив користувач
            // Потрібно, щоб користувач завжди бачив на якій сторінці він знахоидсть
            scrolledOutHeader: '',
            isSyncProgress: false,
            syncProgressInfo: '',
            displaySyncCancelBtn: false,
            isSyncCanceled: false,
            isDefaultDataProgress: false,
        }
    },
    computed: {
        ...mapState([
            'messages', 'toast'
        ]),
    },
    methods: {
        hideOffcanvas() {
            let bsOffCanvasNavBar = Offcanvas.getInstance(this.$refs.offCanvasNavBar);
            bsOffCanvasNavBar.toggle();
        },
        cancelStateSync () {
            this.isSyncCanceled = true;
            this.displaySyncCancelBtn = false;
            this.syncProgressInfo += 'Відміняю...<br>';
        },
        async syncState() {
            this.isSyncProgress = true;
            this.syncProgressInfo = '';
            await this.$store.dispatch(`syncState`, {
                updateProgressInfo: (info, displayCancelBtn = false) => {
                    this.syncProgressInfo += info + '<br>';
                    this.displaySyncCancelBtn = displayCancelBtn;
                },
                isCanceled: () => {
                    if (this.isSyncCanceled) {
                        this.isSyncCanceled = false; // готуємо до наступного разу
                        return true;
                    }
                    return false;
                },
                stopProgressCallback: () => {
                    this.isSyncProgress = false;
                }
            });
        },
        resetState() {
            this.isDefaultDataProgress = true;
            setTimeout(() => {
                this.$store.dispatch(`resetState`);
                this.isDefaultDataProgress = false;
            }, 3000);
        },
        resetMessages () {
            this.$store.commit(`resetMessages`);
        },
        resetToast () {
            this.$store.commit(`resetToast`);
        }
    },
    async beforeCreate() {
        await this.$store.dispatch(`fetchStateFromDb`);
    },
    mounted () {

    }
}
</script>

<style lang="scss">
#app {
    padding: 5.5em 1em 1em 1em;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;

    nav {
        border-bottom: solid 2px White;

        .scrolled-out-header {
            position: relative;
            min-width: 100px;
            a {
                position: absolute;
                display: block;
                font-size: 16px;
                margin: 0px;
                padding: 0px;
                line-height: 14px;
                color: Black;
                text-decoration: none;
            }
            small {
                display: block;
                font-size: 10px;
                margin-top: 20px;
                padding: 0px;
                line-height: 10px;
            }
        }

        li {
            padding-bottom: 0.5em;

            a, span.in-progress {
                text-decoration: none;
                display: block;
                padding: 7px 7px 5px 7px;
                &:not(.in-progress):hover {
                    background-color: aliceblue;
                }
                i {
                    width: 20px;
                }
            }

            small {
                display: block;
                font-size: 0.7em;
                padding-left: 32px;
            }
        }
    }
}
.blob {
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(0.95);
    }
}
</style>
