<template>
    <div class="users-component">
        <div class="buttons text-end">
            <div class="btn-group">
                <button
                    type="button"
                    class="btn btn-success"
                    @click="triggerFilter()"
                >
                    <i class="fa-solid fa-filter" style="margin-right: 0.3em;"></i>
                    Фільтр
                </button>
                <button type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu">
                    <li>
                        <a
                            class="dropdown-item"
                            href="#"
                            @click="triggerFilter()"
                        >
                            <i class="fa-solid fa-filter"></i>
                            Фільтр
                        </a>
                    </li>
                    <li>
                        <a
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            href="#"
                            @click="openAddNewUserForm()"
                        >
                            <i class="fa-regular fa-square-plus"></i>
                            Новий учасник
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="isFilterShown" style="margin-bottom: 15px;">
            Фільтер
        </div>

        <table
            v-if="initUsers?.length"
            class="table align-middle ellipsis-middle-content"
        >
            <thead>
                <th style="width: 2em;">#</th>
                <th>Звати</th>
                <th style="width: 0px;">Рейтинг</th>
                <th style="width: 0px;"></th>
            </thead>
            <tbody>
                <template v-for="(user, index) in initUsers" :key="user.id">
                    <tr>
                        <td>{{ index + 1 }}</td>
                        <td @click="displayFastPanel(user)">
                            {{ user.name }} {{ user.surname }}
                        </td>
                        <td>
                            <UserRating
                                v-if="user.id !== 1"
                                :init-users="initUsers"
                                :init-current-user-id="user.id"
                                @submit="updateUsers($event)"
                            />
                        </td>
                        <td>
                            <div class="btn-group">
                                <router-link
                                    v-if="isWIWI(user)"
                                    :to="'/user/'+user.id+'/progress'"
                                    class="btn btn-light"
                                >
                                    <i class="fa-solid fa-chart-line"></i>
                                </router-link>
                                <router-link
                                    v-else
                                    :to="'/user/'+user.id+'/notes'"
                                    class="btn btn-light"
                                >
                                    <i class="fa-regular fa-note-sticky"></i>
                                </router-link>
                                <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="visually-hidden">Toggle Dropdown</span>
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <router-link
                                            :to="'/user/'+user.id+'/dashboard'"
                                            class="dropdown-item"
                                        >
                                            <i class="fa-solid fa-chart-pie"></i>
                                            Звіт
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="'/user/'+user.id+'/about'"
                                            class="dropdown-item"
                                        >
                                            <i class="fa-solid fa-pen-to-square"></i>
                                            Особисті дані
                                        </router-link>
                                    </li>
                                    <li v-if="!isWIWI(user)">
                                        <router-link
                                            :to="'/user/'+user.id+'/notes'"
                                            class="dropdown-item"
                                        >
                                            <i class="fa-regular fa-note-sticky"></i>
                                            Нотатки
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="'/user/'+user.id+'/progress'"
                                            class="dropdown-item"
                                        >
                                            <i class="fa-solid fa-chart-line"></i>
                                            Прогрес
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="'/user/'+user.id+'/rewards'"
                                            class="dropdown-item"
                                        >
                                            <i class="fa-solid fa-trophy"></i>
                                            Відзнаки
                                        </router-link>
                                    </li>
                                    <li v-if="!isWIWI(user)">
                                        <router-link
                                            :to="'/user/'+user.id+'/punishments'"
                                            class="dropdown-item"
                                        >
                                            <i class="fa-regular fa-face-sad-tear"></i>
                                            Покарання
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="user.fastPanelShown" class="item-description">
                        <td colspan="4">

                        </td>
                    </tr>
                </template>
            </tbody>
        </table>

        <p v-else class="text-center">Немає учасників</p>

        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                            Додати новго учасника
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form>

                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="close()">Закрити</button>
                        <button type="button" class="btn btn-warning" @click="clear()">Відмінити</button>
                        <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="addNewUser()">Зберегти</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserRating from '@/components/atoms/UserRating.vue';

    export default {
        name: 'Users',
        components: {
            UserRating,
        },
        props: {
            initUsers: {
                type: Array,
                required: true
            }
        },
        emits: [ 'submit' ],
        data() {
            return {
                isFilterShown: false,
            }
        },
        computed: {

        },
        methods: {
            isWIWI (user) {
                return user.id == 1;
            },
            triggerFilter () {
                this.isFilterShown = !this.isFilterShown;
            },
            openAddNewUserForm () {
                const emptyItem = {}
                this.currentItemOldData = { ...emptyItem };
                this.currentItem = { ...emptyItem };
            },
            clear () {
                this.currentItem = this.currentItemOldData;
            },
            close () {
                this.clear();
            },
            addNewUser () {

            },
            updateUsers (updatedUsers) {
                this.$emit('submit', updatedUsers);
            },
            displayFastPanel (user) {
                user.fastPanelShown = !user.fastPanelShown;
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    table.ellipsis-middle-content {
        th {
            padding: 0.5rem 0.5rem;
        }
        tr {
            &.item-description {
                background-color: rgba(240, 240, 241, 0.1);
                color: rgb(0, 0, 0, 0.5);
                font-size: 14px;
            }
        }
    }
</style>
