import { createStore } from 'vuex'
import Dexie from 'dexie'
import axios from "axios";
import MESSAGES from './messages'
import STATE_DEFAULT_DATA from './stateDefaultData'

const db = new Dexie('WIWICubs');
db.version(1).stores({
    recoverableState: '++id',
});

const ENDPOINTS = {
    UPDATE_RECOVERABLE_STATE: 'https://www.cubs.wiwi.org.ua/archive/update.php',
    GET_RECOVERABLE_STATE_TIMESTAMP: 'https://www.cubs.wiwi.org.ua/archive/get_archive_timestamp.php',
    GET_RECOVERABLE_STATE: 'https://www.cubs.wiwi.org.ua/archive/get.php',
    // UPDATE_RECOVERABLE_STATE: 'https://dev.secure.sequoiaerp.docker/ddd/update/*.php',
    // GET_STATE: 'https://dev.secure.sequoiaerp.docker/ddd/get.php',*/
}

function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function pushMessage (ctx, messageId, explanation = '', buttons = []) {
    let newMessage = {};
    newMessage[messageId] = {
        id: messageId,
        msg: MESSAGES[messageId],
        exp: explanation,
        buttons: buttons
    }
    return {...ctx.state.messages.list, ...newMessage};
}

export default createStore({
    state: {
        messages: {
            list: {},
            successCallback: msgId => {},
            closeCallback: () => {},
        },
        toast: {
            status: '', // info | success
            message: '',
        },
        recoverable: {}
    },
    getters: {
        lessonsCategorys: (state) => state.recoverable.lessonsCategorys,
        ranksCategorys: (state) => state.recoverable.ranksCategorys,
        ranks: (state) => state.recoverable.ranks,
        skills: (state) => state.recoverable.skills,
        rewardsCategorys: (state) => state.recoverable.rewardsCategorys,
        rewards: (state) => state.recoverable.rewards,
        users: (state) => state.recoverable.users || [],
        articles: (state) => state.recoverable.articles || [],
    },
    mutations: {
        updateMessages (state, { messages, successCallback = msgId => {}, closeCallback = () => {} }) {
            state.messages = {...state.messages, list: messages, successCallback, closeCallback};
        },
        resetMessages (state) {
            state.messages = {};
        },
        updateToast (state, { message = '', status = 'info' }) {
            state.toast = { message, status };
        },
        resetToast (state) {
            state.toast = {message: '', status: 'info'};
        },
        setState (state, recoverableStateData) {
            state.recoverable = {};
            Object.assign(state.recoverable, { ...recoverableStateData });
        },
        updateLastSyncTimestamp (state, timestamp) {
            state.recoverable.lastSyncTimestamp = timestamp;
        },
        updateLastSaveTimestamp (state, timestamp) {
            state.recoverable.lastSaveTimestamp = timestamp;
        },
        updateLessonsCategorys (state, lessonsCategorys) {
            state.recoverable.lessonsCategorys = [ ...lessonsCategorys ];
        },
        updateRanksCategorys (state, ranksCategorys) {
            state.recoverable.ranksCategorys = [ ...ranksCategorys ];
        },
        updateRanks (state, ranks) {
            state.recoverable.ranks = [ ...ranks ];
        },
        updateSkills (state, skills) {
            state.recoverable.skills = [ ...skills ];
        },
        updateRewards (state, rewards) {
            state.recoverable.rewards = [ ...rewards ];
        },
        updateUsers (state, users) {
            state.recoverable.users = [ ...users ];
        },
        updateArticles (state, articles) {
            state.recoverable.articles = [ ...articles ];
        },
    },
    actions: {
        async syncState (ctx, { updateProgressInfo = info => {}, isCanceled = () => false, stopProgressCallback = () => {} }) {
            // Дивимось коли було створено архів на сервері
            updateProgressInfo('Отримую з сервера дату оновлення архівних даних...');
            await sleep(3000);

            let archiveTime = undefined;

            try {
                archiveTime = await axios.post(ENDPOINTS.GET_RECOVERABLE_STATE_TIMESTAMP);
            }
            catch (e) {
                updateProgressInfo(`Не можу зв'язатися з сервером`);
                await sleep(2000);
                updateProgressInfo(`Синхронізація відхилена`);
                ctx.commit('updateMessages', {
                    messages: {
                        ...pushMessage(ctx, 5), // Синхронізація відхилена
                        ...pushMessage(ctx, 6), // Неможу зв'язатися з сервером
                    },
                    closeCallback: () => stopProgressCallback(),
                });
                await sleep(3000);
                return;
            }


            if (typeof archiveTime !== 'object' || !archiveTime.hasOwnProperty('data')) {
                updateProgressInfo(`Отримано невірну дату з сервера`);
                await sleep(2000);
                updateProgressInfo(`Синхронізація відхилена`);
                ctx.commit('updateMessages', {
                    messages: {
                        ...pushMessage(ctx, 5), // Синхронізація відхилена
                        ...pushMessage(ctx, 2), // Отримано невірну дату з сервера
                    },
                    successCallback: () => {},
                    closeCallback: () => stopProgressCallback(),
                });
                await sleep(3000);
                return;
            }
            archiveTime = archiveTime.data;

            if (!archiveTime) {
                updateProgressInfo('На сервері ще немає архівних даних');
            }
            else {
                updateProgressInfo('На сервері дані оновлювались: ' + new Date(archiveTime).toLocaleString("en-GB"));
            }
            await sleep(3000);

            updateProgressInfo('Отримую дату останньої синхронізації...');
            await sleep(3000);

            if (ctx.state.recoverable.hasOwnProperty('lastSyncTimestamp') && ctx.state.recoverable.lastSyncTimestamp) {
                updateProgressInfo('Остання синхронізації відбулась: ' + new Date(ctx.state.recoverable.lastSyncTimestamp).toLocaleString("en-GB"));
            }
            else {
                updateProgressInfo('Дані не знайдено');
            }
            await sleep(3000);

            updateProgressInfo('Отримую дату останнійх змін на клієнті...');
            await sleep(3000);

            if (ctx.state.recoverable.hasOwnProperty('lastSaveTimestamp') && ctx.state.recoverable.lastSaveTimestamp) {
                updateProgressInfo('Остання зміни відбулись: ' + new Date(ctx.state.recoverable.lastSaveTimestamp).toLocaleString("en-GB"));
            }
            else {
                updateProgressInfo('Дані не знайдено');
            }
            await sleep(3000);

            const downloadDataFromServer = async () => {
                updateProgressInfo('Готуюсь отримати дані з сервера...', true);
                await sleep(7000);
                if (isCanceled()) {
                    updateProgressInfo(`Синхронізація відхилена`);
                    await sleep(3000);
                    return false;
                }
                updateProgressInfo('Отримую дані з сервера...');
                await sleep(3000);
                let archiveState = await axios.get(ENDPOINTS.GET_RECOVERABLE_STATE);
                if (typeof archiveState === 'object' && archiveState.hasOwnProperty('data')) {
                    archiveState = archiveState.data;
                    ctx.commit('setState', { ...archiveState });
                    ctx.commit('updateLastSyncTimestamp', Date.now());
                    ctx.dispatch('updateStateToDb');
                    updateProgressInfo('Дані отримано з сервера');
                    ctx.commit('updateToast', { message: 'Дані отримано з сервера', status: 'success' });
                }
                else {
                    ctx.commit('updateMessages', {
                        messages: {
                            ...pushMessage(ctx, 4), // З сервера отримано дані в поганому стані
                            ...pushMessage(ctx, 5), // Синхронізація відхилена
                        }
                    });
                }
                await sleep(3000);
                return true;
            }

            const uploadDataToServer = async () => {
                updateProgressInfo('Готуюсь завантажити дані на сервер...', true);
                await sleep(7000);
                if (isCanceled()) {
                    updateProgressInfo(`Синхронізація відхилена`);
                    await sleep(3000);
                    return false;
                }
                updateProgressInfo('Завантажую дані на сервер...');
                await sleep(3000);
                await axios.post(ENDPOINTS.UPDATE_RECOVERABLE_STATE, ctx.state.recoverable);
                // запам'ятаємо дату синхронізації
                ctx.commit('updateLastSyncTimestamp', Date.now());
                ctx.dispatch('updateStateToDb');
                updateProgressInfo('Дані завантажено на сервер');
                ctx.commit('updateToast', { message: 'Дані завантажено на сервер', status: 'success' });
                await sleep(3000);
            }

            // Якщо дата архіва старша попередньої дати синхронізації (отже архів вже хтось інший обновив раніше),
            // то викачаєм архів
            if (
                typeof ctx.state.recoverable !== 'object'
                ||
                !ctx.state.recoverable.hasOwnProperty('lastSyncTimestamp')
                ||
                archiveTime > ctx.state.recoverable.lastSyncTimestamp
            )
            {
                updateProgressInfo('Після останньої синхронізації, серверні дані обновлювались');
                await sleep(4000);

                ctx.commit('updateMessages', {
                    messages: pushMessage(
                        ctx,
                        3, // З моменту останньої синхронізації, дані на сервері були оновлені
                        '',
                        [
                            {
                                alias: 'download',
                                name: 'Отримати',
                                colorClass: 'btn-success'
                            },
                            {
                                alias: 'upload',
                                name: 'Віддати',
                                colorClass: 'btn-primary'
                            },
                        ]
                    ),
                    successCallback: async (buttonAlias) => {
                        if (buttonAlias === 'download') {
                            await downloadDataFromServer();
                        }
                        else if (buttonAlias === 'upload') {
                            await uploadDataToServer();
                        }
                        stopProgressCallback();
                    },
                    closeCallback: () => {
                        ctx.commit('updateToast', { message: MESSAGES[5] }); // Синхронізація відхилена
                        stopProgressCallback();
                    }
                });
            }
            // інакше завантажимо його на сервер
            else {
                updateProgressInfo('На сервері дані не новіші, як на клієнті (як у Вас)');
                await sleep(4000);
                await uploadDataToServer();
                stopProgressCallback();
            }
        },

        async resetState (ctx) {
            ctx.commit('setState', STATE_DEFAULT_DATA);
            ctx.commit('updateLastSyncTimestamp', Date.now());
            await ctx.dispatch(`updateStateToDb`);
            ctx.commit('updateToast', { message: 'Встановлено дані по замовчуванню', status: 'success' });
        },

        async fetchStateFromDb (ctx) {

            db.transaction("rw", 'recoverableState', async () => {

                const recoverableState = await db.recoverableState.get(1);

                if (recoverableState) {
                    ctx.commit('setState', { ...recoverableState });
                    console.log('State has been fetched');
                }
                else {
                    console.log('State does not exist in db');
                }
            }).catch(e => {
                console.log('Can not create transaction because: ', e);
            });
        },

        async updateStateToDb (ctx) {

            db.transaction("rw", 'recoverableState', async () => {

                const recoverableState = JSON.parse(JSON.stringify(ctx.state.recoverable))

                const existState = await db.recoverableState.get(1);

                if (existState) {
                    await db.recoverableState.update(1, { ...recoverableState });
                }
                else {
                    await db.recoverableState.add({ ...recoverableState });
                }

                ctx.commit('updateLastSaveTimestamp', Date.now());

                ctx.commit('updateToast', { message: 'Дані збережено', status: 'success' });

                console.log('State has been updated');
            }).catch(e => {
                console.log('Can not create transaction because: ', e);
            });
        },

        sync () {

        }
    },
    modules: {
    }
})
